<template>
  <!--<h1>This is Practise page</h1>-->
  <router-view />
</template>

<style></style>

<script>
export default {
  inject: ["globalVariable"],
};
</script>
